import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonPopover,
  IonInput,
  IonSpinner,
} from "@ionic/react";
import "./Registration.css";
import axios from "axios";
import { useState } from "react";
import configs from "../../configs/index";
import Logo from "../../assets/gm_logo.png";
import i18n from "../../Translations/i18nConfigs";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    username: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    mob_number: "",
    business_name: "",
    groups: "supplier",
  });
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [Validations, setValidations] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const RegistrationAPI = () => {
    setSpinner(true);
    axios
      .post(`${configs.base_url}${configs.api_url}register/`, data)
      .then((response) => {
        console.log(response.data);
        setSpinner(false);
        setShowPopover(true);

        setTimeout(() => {
          window.location.assign("/login");
        }, 2000);
      })
      .catch((error) => {
        console.log(error.response.data);
        setValidations(error.response.data);
        setSpinner(false);
        setShowPopover2(true);
      });
  };

  const API = () => {
    window.location.assign("/login");
  };

  return (
    <>
      <IonPage>
        <IonContent>
          <IonGrid className="container-grid">
            <IonRow>
              <IonCol
                className="container-col"
                size="12"
                size-sm="3"
                size-md="12"
                size-lg="6"
              >
                <IonRow className="rightcol">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonLabel>SUPPLIER PLATFORM</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonLabel>WILLKOMMEN BEI DER GASTRONOMIA GMBH</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol size="12" size-sm="3" size-md="12" size-lg="6">
                <IonRow className="leftcol">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <img
                          src={Logo}
                          alt="Gastronomia Logo"
                          className="logoimg"
                        ></img>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonLabel className="pageheader">
                          {i18n.t("signup.title")}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonGrid>
                          <IonRow>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>
                                    {i18n.t("signup.username")}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t(
                                      "signup.usernamePlaceholder"
                                    )}
                                    id="reginputtxt"
                                    value={data.username}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        username: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>{i18n.t("signup.email")}</IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t(
                                      "signup.emailPlaceholder"
                                    )}
                                    id="reginputtxt"
                                    value={data.email}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        email: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>
                                    {i18n.t("signup.password")}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t(
                                      "signup.passwordPlaceholder"
                                    )}
                                    id="reginputtxt"
                                    type="password"
                                    value={data.password1}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        password1: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>
                                    {i18n.t("signup.confirmPass")}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t("signup.confirmPass")}
                                    id="reginputtxt"
                                    type="password"
                                    value={data.password2}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        password2: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>
                                    {i18n.t("signup.firstName")}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t(
                                      "signup.firstNamePlaceholder"
                                    )}
                                    id="reginputtxt"
                                    value={data.first_name}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        first_name: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>
                                    {i18n.t("signup.lastName")}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t(
                                      "signup.lastNamePlaceholder"
                                    )}
                                    id="reginputtxt"
                                    value={data.last_name}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        last_name: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>
                                    {i18n.t("signup.mobileNumber")}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t(
                                      "signup.mobileNumberPlaceholder"
                                    )}
                                    id="reginputtxt"
                                    value={data.mob_number}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        mob_number: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                            <IonCol
                              size="12"
                              size-sm="3"
                              size-md="12"
                              size-lg="6"
                            >
                              <IonRow>
                                <IonCol>
                                  <IonLabel>
                                    {i18n.t("signup.businessName")}
                                  </IonLabel>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <IonInput
                                    placeholder={i18n.t(
                                      "signup.businessNamePlaceholder"
                                    )}
                                    id="reginputtxt"
                                    value={data.business_name}
                                    onIonChange={(event) =>
                                      setData({
                                        ...data,
                                        business_name: event.detail.value,
                                      })
                                    }
                                  ></IonInput>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="btncol">
                        {!spinner && (
                          <IonButton
                            expand="block"
                            className="loginbtn"
                            onClick={RegistrationAPI}
                          >
                            {i18n.t("signup.title")}
                          </IonButton>
                        )}
                        {spinner && (
                          <IonButton
                            expand="block"
                            className="loginbtn"
                            onClick={RegistrationAPI}
                          >
                            <IonSpinner></IonSpinner>
                          </IonButton>
                        )}
                        <IonPopover
                          isOpen={showPopover}
                          onDidDismiss={() => setShowPopover(false)}
                          className="confirmationbox"
                        >
                          <p className="confirmationmessage">
                            {i18n.t("signup.regSuccess")}
                            <br></br> {i18n.t("signup.wait")}
                          </p>
                        </IonPopover>

                        <IonPopover
                          className="confirmationbox"
                          isOpen={showPopover2}
                          onDidDismiss={() => setShowPopover2(false)}
                        >
                          {Validations &&
                            Object.keys(Validations).map((fieldName, index) => (
                              <p
                                className="confirmationmessage"
                                key={index.toString()}
                              >
                                {`${fieldName}: ${Validations[fieldName].join(
                                  ", "
                                )}`}
                              </p>
                            ))}
                        </IonPopover>
                      </IonCol>
                    </IonRow>

                    <IonRow className="signuprow">
                      <IonCol>
                        <IonLabel>{i18n.t('signup.haveAnAccount')}</IonLabel> {""}
                        <IonLabel className="link" onClick={API}>
                        {i18n.t('signup.login')}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Login;
