import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSearchbar,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonCard,
  IonCardContent,
  IonPopover,
  IonContent,
  IonInput,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonSelectOption,
  IonSelect,
  IonSpinner,
  IonDatetime,
  IonDatetimeButton,
  IonTextarea,
  IonApp,
} from "@ionic/react";
import Navigation from "../../components/Navigation";
import "./Orders.css";
import { downloadOutline, calendarOutline } from "ionicons/icons";
import configs from "../../configs/index";
//libraries needed for download pdf
import i18n from "../../Translations/i18nConfigs";
import { FileOpener } from "@ionic-native/file-opener";
import { File } from "@ionic-native/file";
import { isPlatform } from "@ionic/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AuthContext from "../../context/AuthContext";
import Pagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/bootstrap.css";

const Orders = () => {
  const { accesstoken } = useContext(AuthContext);
  const CompanyID = localStorage.getItem("companyID");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchProducts, setsearchProducts] = useState("");
  const [sortOrder, setSortOrder] = useState("descending");
  const [isActive, setIsActive] = useState("All");
  const [spinner, setSpinner] = useState(false);
  const [selected, setSelected] = useState("all");

  //pagiantion
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //TotalPage computation
  const totalPages = totalRecords < 4 ? 1 : Math.ceil(totalRecords / 4);

  //popover
  const [showPopover, setShowPopover] = useState(false);
  const [message, setMessage] = useState(false);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const [Filter, setFilter] = useState({
    filteredby: "Filter",
  });

  const [Orders, setOrders] = useState([]);
  useEffect(() => {
    const OrdersList = async () => {
      setSpinner(true);
      return axios
        .get(
          `${configs.base_url}${configs.api_url}transaction/mytransactions/?order=${sortOrder}&search=${searchProducts}&status=${selected}&page=${currentPage}&page_size=4`,
          {
            headers: {
              authorization: "Bearer " + accesstoken,
            },
          }
        )

        .then((response) => {
          const reponseResult = response?.data.results;
          setTotalRecords(response.data.count);
          setOrders(reponseResult);
          // console.log(response);
          setSpinner(false);
        });
    };
    OrdersList();
  }, [sortOrder, searchProducts, selected, currentPage]);

  const [ConfirmOrder, setConfirmOrder] = useState({
    item_total: 0,
    tax_fee: 0,
    other_fee: 0,
    delivery_fee: 0,
    delivery_date: "",
    seller_notes: "",
    fob_point: "",
    terms: "",
    shipped_via: "",
    sales_agent: "",
  });
  // console.log(ConfirmOrder);
  async function Action(ID, action) {
    setSpinner(true);
    axios
      .patch(
        `${configs.base_url}${configs.api_url}transaction/mytransactions/` +
          ID +
          "/",
        {
          status: action,
          delivery_date:
            ConfirmOrder.delivery_date === ""
              ? null
              : ConfirmOrder.delivery_date,

          ...(ConfirmOrder.item_total !== null &&
            ConfirmOrder.item_total !== "" && {
              item_total: ConfirmOrder.item_total,
            }),
          ...(ConfirmOrder.tax_fee !== null &&
            ConfirmOrder.tax_fee !== "" && {
              tax_fee: ConfirmOrder.tax_fee,
            }),
          ...(ConfirmOrder.other_fee !== null &&
            ConfirmOrder.other_fee !== "" && {
              other_fee: ConfirmOrder.other_fee,
            }),
          ...(ConfirmOrder.delivery_fee !== null &&
            ConfirmOrder.delivery_fee !== "" && {
              delivery_fee: ConfirmOrder.delivery_fee,
            }),
          ...(ConfirmOrder.fob_point !== null &&
            ConfirmOrder.fob_point !== "" && {
              fob_point: ConfirmOrder.fob_point,
            }),
          ...(ConfirmOrder.terms !== null &&
            ConfirmOrder.terms !== "" && {
              terms: ConfirmOrder.terms,
            }),
          ...(ConfirmOrder.seller_notes !== null &&
            ConfirmOrder.seller_notes !== "" && {
              seller_notes: ConfirmOrder.seller_notes,
            }),
          ...(ConfirmOrder.shipped_via !== null &&
            ConfirmOrder.shipped_via !== "" && {
              shipped_via: ConfirmOrder.shipped_via,
            }),
          ...(ConfirmOrder.sales_agent !== null &&
            ConfirmOrder.sales_agent !== "" && {
              sales_agent: ConfirmOrder.sales_agent,
            }),
        },
        {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        setSpinner(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  }

  async function delivered(ID, action) {
    setSpinner(true);
    axios
      .patch(
        `${configs.base_url}${configs.api_url}transaction/mytransactions/` +
          ID +
          "/",
        {
          status: action,
        },
        {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        setSpinner(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  }
  async function cancel(ID, action) {
    setSpinner(true);
    axios
      .patch(
        `${configs.base_url}${configs.api_url}transaction/mytransactions/` +
          ID +
          "/",
        {
          status: action,
        },
        {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        setSpinner(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  }

  //customer code
  const [customercode, setCustomercode] = useState();
  //get customer code

  async function getCustomerCode(id) {
    axios
      .get(
        `${configs.base_url}${configs.api_url}users/customer-details/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      )
      .then((response) => {
        setCustomercode(response.data.customer_no);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // console.log(customercode);

  //storage of detailed transaction
  const [detailedTransaction, setDetailedTransaction] = useState();

  //function for getting the detailed transaction
  async function getTransactions(id) {
    //to close the modal first before opening the modal again
    setIsOpen(false);
    axios
      .get(
        `${configs.base_url}${configs.api_url}transaction/mytransactions/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      )
      .then((response) => {
        setDetailedTransaction(response.data);
        setIsOpen(true);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [Orderview, setOrderview] = useState({
    image: "",
    name: "",
    CustomerName: "",
    order_no: "",
    created_at: "",
    time: "",
    requested_delivery_date: "",
    comment: "",
    address: "",
    delivery_fee: 0,
    get_total: 0,
    item_total: 0,
    quantity: 0,
    price: 0,
  });
  // console.log(Orderview);

  const ViewShow = (
    image,
    name,
    customerFirstname,
    customerLastname,
    order_no,
    created_at,
    requested_delivery_date,
    comment,
    address,
    delivery_fee,
    get_total,
    item_total,
    quantity,
    price
  ) => {
    // split the created_at parameter into date and time strings
    const [dateStr, timeStr, meridian] = created_at.split(" ");

    // remove the seconds and timezone offset from the time string
    const formattedTimeStr = timeStr.slice(0, -2) + timeStr.slice(-2);
    setOrderview({
      image: image,
      name: name,
      CustomerName: customerFirstname + " " + customerLastname,
      order_no: order_no,
      created_at: dateStr,
      time: formattedTimeStr + " " + meridian,
      requested_delivery_date: requested_delivery_date,
      comment: comment,
      address: address,
      delivery_fee: delivery_fee,
      get_total: get_total,
      item_total: item_total,
      quantity: quantity,
      price: price,
    });
    window.innerWidth < 768 ? setIsOpen(true) : handleOpenModal(true);
  };

  // screensize
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const [imagespop, setimagespop] = useState(false);
  const [imagespopModalOpen, setimagespopModalOpen] = useState(false);
  const imagespopOpenModal = () => {
    setimagespopModalOpen(true);
  };

  const imagespopCloseModal = () => {
    setimagespopModalOpen(false);
  };
  const urls = Orderview.image.split(", ");

  const handleButtonClick = (value) => {
    setSelected(value);
    setCurrentPage(1);
  };

  //this is for downloading the pdf
  //separate this to other page soon..

  //spinners
  const [downloadLoading, setDonwloadLoading] = useState(false);
  //pdf file
  const [saveFile, setSaveFile] = useState();

  async function downloadPDF(choice) {
    if (choice === "download") {
      setDonwloadLoading(true);
    }
    const doc = new jsPDF();

    // Add the Purchase Order title
    doc.setFontSize(18);
    doc.setFillColor(0);
    //title
    doc.text(
      `${i18n.t("transaction.po")} - ${detailedTransaction?.customer.company} `,
      15,
      20
    );
    doc.setFontSize(10);
    doc.text(`${i18n.t("transaction.for")} Gastronomia GmbH`, 15, 25);

    //ordered
    doc.text(`${i18n.t("transaction.ordered")}`, 15, 35);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${new Date(detailedTransaction?.created_at).toLocaleDateString("de", {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`,
      15,
      40
    );
    doc.setFont("helvetica", "normal");
    //order number
    doc.text(`${i18n.t("transaction.orderNumber")}`, 15, 50);
    doc.setFont("helvetica", "bold");
    doc.text(detailedTransaction?.order_no, 15, 55);
    doc.setFont("helvetica", "normal");

    //ordered by
    doc.text(`${i18n.t("transaction.orderedBy")}`, 15, 65);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${detailedTransaction?.customer.first_name} ${detailedTransaction?.customer.last_name} (${detailedTransaction?.customer.mob_number})`,
      15,
      70
    );
    doc.setFont("helvetica", "normal");

    //requested delivery date
    doc.text(`${i18n.t("transaction.requestedDeliveryDate")}`, 100, 35);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${new Date(
        detailedTransaction?.requested_delivery_date
      ).toLocaleDateString("de", {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`,
      100,
      40
    );
    doc.setFont("helvetica", "normal");

    //customer number
    doc.text(`${i18n.t("transaction.customerNumber")}`, 100, 50);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${customercode} (${detailedTransaction?.customer.company})`,
      100,
      55
    );
    doc.setFont("helvetica", "normal");

    //delivery address
    doc.text(`${i18n.t("transaction.deliveryAddress")}`, 100, 65);
    doc.setFont("helvetica", "bold");
    doc.text(`${detailedTransaction?.address}`, 100, 70);
    doc.setFont("helvetica", "normal");

    doc.setFontSize(10);
    // Add the table with the purchase order details
    // Group products by category
    const productsByCategory = detailedTransaction?.order.reduce(
      (result, item) => {
        if (!result[item.item_snap.category]) {
          result[item.item_snap.category] = [];
        }
        result[item.item_snap.category].push(item);
        return result;
      },
      {}
    );

    // Flattened data for autoTable
    const orderData = [];

    // Iterate through categories and products
    Object.keys(productsByCategory).forEach((category) => {
      // Add category row
      const boldCategoryRow = [
        {
          content: category,
          colSpan: 6,
          styles: { fontStyle: "bold", textColor: [0, 0, 0] },
        },
      ];
      orderData.push(boldCategoryRow);

      // Add product rows
      productsByCategory[category].forEach((item) => {
        const productRow = [
          item.item_snap.product_id,
          item.item_snap.name,
          item.item_snap.units,
          item.quantity,
          "",
          item.price,
        ];
        orderData.push(productRow);
      });
    });

    // Add summary rows
    const subtotalRow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.subTotal"),
      detailedTransaction?.item_total,
    ];
    orderData.push(subtotalRow);

    const salesrow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.salesTax"),
      `${detailedTransaction?.tax_fee}`,
    ];
    orderData.push(salesrow);

    const shippingrow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.shippingHandling"),
      detailedTransaction?.delivery_fee,
    ];
    orderData.push(shippingrow);

    const other = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.other"),
      `${detailedTransaction?.other_fee}`,
    ];
    orderData.push(other);

    const totalrow = [
      "",
      "",
      "",
      "",
      i18n.t("transaction.total"),
      detailedTransaction?.get_total,
    ];
    orderData.push(totalrow);

    // Set initial y-coordinate for the table
    let tableY = 80;

    // Iterate through chunks of the orderData and add them to the table
    for (let i = 0; i < orderData.length; i += 30) {
      const chunk = orderData.slice(i, i + 30);
      doc.autoTable({
        startY: tableY,
        head: [
          [
            i18n.t("transaction.productId"),
            i18n.t("transaction.productName"),
            i18n.t("transaction.unit"),
            i18n.t("transaction.quantityOrdered"),
            i18n.t("transaction.quantityDelivered"),
            i18n.t("transaction.unitPrice"),
          ],
        ],
        body: chunk,
        styles: { cellPadding: 2, fontSize: 9 },
        headStyles: {
          fillColor: [255, 255, 255], // No color for the header
          textColor: [0, 0, 0], // Black text for the header
        },
        columnStyles: {
          0: { textAlign: "center" },
          1: { textAlign: "center" },
          3: { textAlign: "center" },
          4: { textAlign: "center" },
          5: { textAlign: "center" },
        },
      });
      tableY = doc.previousAutoTable.finalY + 10;

      // Add a new page if there are more chunks
      if (i + 30 < orderData.length) {
        doc.addPage();
      }
    }

    doc.setTextColor("black");
    doc.text(
      `${detailedTransaction?.customer.first_name} ${detailedTransaction?.customer.last_name}`,
      115,
      tableY + 30
    );
    doc.text(`${i18n.t("transaction.authBy")}`, 115, tableY + 40);
    doc.text(
      `${new Date(detailedTransaction?.created_at).toLocaleDateString("de", {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`,
      155,
      tableY + 30
    );
    doc.text(`${i18n.t("transaction.date")}`, 160, tableY + 40);
    if (choice === "download") {
      // Save the document
      let pdfOutput = doc.output();

      if (isPlatform("android")) {
        // for Android device
        setDonwloadLoading(false);
        const directory = File.externalRootDirectory + "Download/";
        const fileName = `Purchase_Order-${
          detailedTransaction?.order_no
        }_${new Date().getTime()}.pdf`;
        File.writeFile(directory, fileName, pdfOutput, true).then(
          (succ) => {
            FileOpener.showOpenWithDialog(
              directory + fileName,
              "application/pdf"
            )
              .then(() => console.log("File opened"))
              .catch((error) => console.log("Error opening file", error));
          },
          (err) => {
            console.log(" writing File error : ", err);
          }
        );
      } else if (isPlatform("ios")) {
        // for iOS device
        console.log("ios device");
        const directory = File.tempDirectory;
        const fileName = `Purchase_Order-${
          detailedTransaction?.order_no
        }_${new Date().getTime()}.pdf`;
        File.writeFile(directory, fileName, pdfOutput, true).then(
          (success) => {
            FileOpener.showOpenWithDialog(
              directory + fileName,
              "application/pdf"
            )
              .then(() => console.log("File opened"))
              .catch((e) => console.log("Error opening file", e));
          },
          (err) => {
            console.log(" writing File error : ", err);
          }
        );
      } else {
        // for desktop
        doc.save(
          `Purchase_Order-${
            detailedTransaction?.order_no
          }_${new Date().getTime()}.pdf`
        );
        setMessage("Purchase Order is succesfully downloaded");
        setShowPopover(true);
        setDonwloadLoading(false);
      }
    }
  }

  const [isOpen2, setIsOpen2] = useState(false);
  const [UserAgents, setUserAgents] = useState([]);

  useEffect(() => {
    const agents = (e) => {
      setSpinner(true);
      axios
        .get(`${configs.base_url}${configs.api_url}users/list/`, {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        })
        .then((response) => {
          // console.log(response);
          setUserAgents(response.data);
          setSpinner(false);
        })
        .catch((error) => {
          console.log(error);
          setSpinner(false);
        });
    };
    agents();
  }, []);
  // console.log(UserAgents);
  return (
    <IonApp>
      <IonContent>
        <Navigation />
        <IonGrid>
          <IonRow>
            <IonCol className="pagetitle">
              <h3>{i18n.t("order.title")}</h3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="sortcol">
              <IonItem>
                <IonLabel>{i18n.t("order.sortBy")}</IonLabel>
                <IonSelect
                  placeholder="Select One"
                  onIonChange={(e) => {
                    setSortOrder(e.detail.value);
                  }}
                  value={sortOrder}
                >
                  <IonSelectOption value="descending">
                    {i18n.t("order.latest")}
                  </IonSelectOption>
                  <IonSelectOption value="ascending" selected>
                    {i18n.t("order.oldest")}
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="searchcol">
              <IonSearchbar
                placeholder={i18n.t("order.searchOrder")}
                value={searchProducts}
                onIonChange={(e) => setsearchProducts(e.detail.value)}
              ></IonSearchbar>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="actionlistcol">
              <IonRow>
                <IonList className="actionslist">
                  <IonCol>
                    <IonButton
                      color={selected === "all" ? "primary" : "light"}
                      onClick={() => handleButtonClick("all")}
                    >
                      {i18n.t("order.all")}
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color={selected === "delivered" ? "primary" : "light"}
                      onClick={() => handleButtonClick("delivered")}
                    >
                      {i18n.t("order.delivered")}
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color={selected === "confirmed" ? "primary" : "light"}
                      onClick={() => handleButtonClick("confirmed")}
                    >
                      {i18n.t("order.fordelivery")}
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color={selected === "pending" ? "primary" : "light"}
                      onClick={() => handleButtonClick("pending")}
                    >
                      {i18n.t("order.pending")}
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color={selected === "cancelled" ? "primary" : "light"}
                      onClick={() => handleButtonClick("cancelled")}
                    >
                      {i18n.t("order.cancelled")}
                    </IonButton>
                  </IonCol>
                </IonList>
              </IonRow>
            </IonCol>
            <IonCol className="exportbtncol">
              <IonRow>
                <IonCol>
                  {/* <IonButton expand="block" className="exportbtn">
                    <IonIcon
                      icon={downloadOutline}
                      style={{ marginRight: "10px" }}
                    />
                    Export Orders
                  </IonButton> */}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          {!spinner && (
            <IonRow>
              <IonCol className="cardlistcol">
                {Orders?.map((order, index) => (
                  <IonCard key={index.toString()}>
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="12"
                            size-lg="2"
                            className="listcol"
                          >
                            <h4 className="ion-text-center">
                              <strong>{i18n.t("order.name")} </strong>
                              {order.customer.company}
                            </h4>
                          </IonCol>

                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="12"
                            size-lg="2"
                            className="listcol"
                          >
                            <h4 className="ion-text-center">
                              <strong>#{order.order_no}</strong>
                            </h4>
                          </IonCol>

                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="12"
                            size-lg="2"
                            className="listcol"
                          >
                            <h4 className="ion-text-center">
                              {i18n.t("order.saleagent")}{" "}
                              {order.sales_agent_name === null
                                ? `${i18n.t("order.nosaleagent")}`
                                : order.sales_agent_name}
                            </h4>
                          </IonCol>

                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="12"
                            size-lg="2"
                            className="listcol"
                          >
                            <h4 className="ion-text-center">
                              {i18n.t("order.orderDate")}: {order.created_at}
                            </h4>
                          </IonCol>

                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="12"
                            size-lg="2"
                            className="listcol"
                          >
                            <h4 className="ion-text-center">
                              {i18n.t("order.reqDeliveryDate")}:{" "}
                              {order.requested_delivery_date}
                            </h4>
                          </IonCol>

                          <IonCol
                            size="12"
                            size-sm="3"
                            size-md="12"
                            size-lg="2"
                          >
                            <IonButton
                              expand="block"
                              onClick={() => {
                                getTransactions(order.id);
                                getCustomerCode(order.customer.id); // Assuming you can access customer_id from order object
                              }}
                            >
                              {order.status === "Confirm"
                                ? `${i18n.t("order.fordelivery")}`
                                : order.status === "Delivered"
                                ? `${i18n.t("order.view")}`
                                : order.status === "Pending"
                                ? `${i18n.t("order.review")}`
                                : `${i18n.t("order.cancelled")}`}
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                ))}

                <IonRow>
                  <IonCol size-lg="2" className="pagination">
                    <Pagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          )}
          {spinner && (
            <IonRow>
              <IonCol className="cardinfocol">
                <IonCard className="marginpaddingzero">
                  <IonCardContent className="infoinsidecards">
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          {" "}
                          <IonSpinner></IonSpinner>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>

        <IonPopover
          className="errorvalidationpopover"
          isOpen={showPopover}
          onDidDismiss={() => setShowPopover(false)}
        >
          <ion-content class="ion-padding">{message}</ion-content>
        </IonPopover>

        {/* Order modal START */}
        <IonModal
          isOpen={isOpen}
          style={{
            "--width": `${isSmallScreen ? "100%" : "70vw"}`,
            "--height": `${isSmallScreen ? "100%" : "80vh"}`,
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                {i18n.t("transaction.title")} {detailedTransaction?.order_no}
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>
                  {i18n.t("transaction.close")}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding" id="pdf-container">
            <IonGrid>
              <IonRow>
                <IonCol size="6"></IonCol>
                <IonCol size="6">
                  {/* {!downloadLoading && ( */}
                  <IonButton
                    style={{
                      "--background": "transparent",
                      "--color": "black",
                      float: "right",
                    }}
                    onClick={() => downloadPDF("download")}
                  >
                    <IonIcon
                      style={{ marginRight: 10 }}
                      icon={downloadOutline}
                    ></IonIcon>
                    {i18n.t("transaction.downloadAsPdf")}
                  </IonButton>
                  {/* )}
                      {downloadLoading && ( */}

                  {/* )} */}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" style={{ textAlign: "left" }}>
                  <p style={{ fontSize: "20px" }}>
                    <b>{`${i18n.t("transaction.po")} - ${
                      detailedTransaction?.customer.company
                    }`}</b>
                    <p
                      style={{ fontSize: "14px", lineHeight: "0px" }}
                    >{`${i18n.t("transaction.for")} Gastronomia GmbH`}</p>
                  </p>
                </IonCol>
                <IonCol size="12" sizeLg="6">
                  <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                    "transaction.ordered"
                  )}`}</p>
                  <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                    <b>
                      {new Date(
                        detailedTransaction?.created_at
                      ).toLocaleDateString("de", {
                        weekday: "short",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </b>
                  </p>

                  <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                    "transaction.orderNumber"
                  )}`}</p>
                  <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                    <b>{detailedTransaction?.order_no}</b>
                  </p>

                  <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                    "transaction.orderedBy"
                  )}`}</p>
                  <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                    <b>{`${detailedTransaction?.customer.first_name} ${detailedTransaction?.customer.last_name} (${detailedTransaction?.customer.mob_number})`}</b>
                  </p>
                </IonCol>

                <IonCol size="12" sizeLg="6">
                  <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                    "transaction.requestedDeliveryDate"
                  )}`}</p>
                  <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                    <b>
                      {new Date(
                        detailedTransaction?.requested_delivery_date
                      ).toLocaleDateString("de", {
                        weekday: "short",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </b>
                  </p>

                  <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                    "transaction.customerNumber"
                  )}`}</p>
                  <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                    <b>{`${customercode} (${detailedTransaction?.customer.company})`}</b>
                  </p>

                  <p style={{ lineHeight: "0px" }}>{`${i18n.t(
                    "transaction.deliveryAddress"
                  )}`}</p>
                  <p style={{ lineHeight: "5px", marginBottom: "30px" }}>
                    <b>{`${detailedTransaction?.address}`}</b>
                  </p>
                </IonCol>
              </IonRow>

              <IonRow style={{ marginTop: 20, overflowX: "auto" }}>
                <IonCol>
                  <table className="tableStyles">
                    <thead className="theadStyles">
                      <tr className="trStyles" style={{ color: "black" }}>
                        <th>{i18n.t("transaction.productId")}</th>
                        <th>{i18n.t("transaction.productName")}</th>
                        <th>{i18n.t("transaction.unit")}</th>
                        <th>{i18n.t("transaction.quantityOrdered")}</th>
                        <th>{i18n.t("transaction.quantityDelivered")}</th>
                        <th>{i18n.t("transaction.unitPrice")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailedTransaction?.order.reduce(
                        (rows, item, index) => {
                          // Add a category row if it's a new category
                          if (
                            index === 0 ||
                            detailedTransaction.order[index - 1].item_snap
                              .category !== item.item_snap.category
                          ) {
                            rows.push(
                              <tr
                                key={`category_${index}`}
                                style={{
                                  textAlign: "left",
                                  background: "#ddd",
                                }}
                              >
                                <td colSpan="6">
                                  <b style={{ marginLeft: "20px" }}>
                                    {item.item_snap.category}
                                  </b>
                                </td>
                              </tr>
                            );
                          }

                          // Add the item row
                          rows.push(
                            <tr key={index} style={{ textAlign: "center" }}>
                              <td>{item.item_snap.product_id}</td>
                              <td>{item.item_snap.name}</td>
                              <td>{item.item_snap.units}</td>
                              <td>{item.quantity}</td>
                              <td></td>
                              <td>{item.price}</td>
                            </tr>
                          );

                          return rows;
                        },
                        []
                      )}
                      {/* Subtotal row */}
                      <tr style={{ textAlign: "center" }}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{i18n.t("transaction.subTotal")}</b>
                        </td>
                        <td>{detailedTransaction?.item_total}</td>
                      </tr>
                      {/* Sales Tax row */}
                      <tr style={{ textAlign: "center" }}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{i18n.t("transaction.salesTax")}</b>
                        </td>
                        <td>{detailedTransaction?.tax_fee}</td>
                      </tr>
                      {/* Shipping and Handling row */}
                      <tr style={{ textAlign: "center" }}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{i18n.t("transaction.shippingHandling")}</b>
                        </td>
                        <td>{detailedTransaction?.delivery_fee}</td>
                      </tr>
                      {/* Other Fees row */}
                      <tr style={{ textAlign: "center" }}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{i18n.t("transaction.other")}</b>
                        </td>
                        <td>{detailedTransaction?.other_fee}</td>
                      </tr>
                      {/* Total row */}
                      <tr style={{ textAlign: "center" }}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{i18n.t("transaction.total")}</b>
                        </td>
                        <td>{detailedTransaction?.get_total}</td>
                      </tr>
                    </tbody>
                  </table>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6"></IonCol>
                <IonCol size="6">
                  <IonRow>
                    <IonCol style={{ textAlign: "center" }}>
                      <p>
                        <u>
                          {detailedTransaction?.customer.first_name}{" "}
                          {detailedTransaction?.customer.last_name}
                        </u>
                      </p>
                      <p>
                        <b>{i18n.t("transaction.authBy")}</b>
                      </p>
                    </IonCol>
                    <IonCol style={{ textAlign: "center" }}>
                      <p>
                        <u>
                          {new Date(
                            detailedTransaction?.created_at
                          ).toLocaleDateString("de", {
                            weekday: "short",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </u>
                      </p>
                      <p>
                        <b>{i18n.t("transaction.date")}</b>
                      </p>
                    </IonCol>
                  </IonRow>
                </IonCol>

                <IonCol size="12" size-sm="3" size-md="12" size-lg="12">
                  {detailedTransaction?.status === "Pending" ? (
                    <IonRow>
                      <IonCol className="lowerbtn-txtmodal">
                        {!spinner && (
                          <IonButton
                            onClick={() =>
                              cancel(detailedTransaction.id, "Cancel")
                            }
                          >
                            {i18n.t("transaction.reject")}
                          </IonButton>
                        )}
                        {spinner && (
                          <IonButton
                            onClick={() =>
                              cancel(detailedTransaction.id, "Cancel")
                            }
                          >
                            <IonSpinner></IonSpinner>
                          </IonButton>
                        )}
                      </IonCol>
                      <IonCol className="lowerbtnmodal" size-md="2" size-lg="2">
                        <IonButton onClick={() => setIsOpen2(true)}>
                          {i18n.t("transaction.confirm")}
                        </IonButton>

                        <IonModal isOpen={isOpen2}>
                          <IonHeader>
                            <IonToolbar>
                              <IonTitle>Confirmation Details</IonTitle>
                              <IonButtons slot="end">
                                <IonButton onClick={() => setIsOpen2(false)}>
                                  Close
                                </IonButton>
                              </IonButtons>
                            </IonToolbar>
                          </IonHeader>
                          <IonContent className="ion-padding">
                            <IonRow>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Delivery Date
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonRow
                                  // style={{
                                  //   border: "1px solid #E3E3E3",
                                  //   borderRadius: "3px",
                                  //   padding: "10px",
                                  //   marginTop: "10px",
                                  //   marginBottom: "10px",
                                  // }}
                                  className="calendarbox"
                                >
                                  <IonIcon
                                    icon={calendarOutline}
                                    style={{
                                      marginRight: "10px",
                                      fontSize: "25px",
                                      color: "#655E5F",
                                    }}
                                  ></IonIcon>
                                  <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                                  <IonModal keepContentsMounted={true}>
                                    <IonDatetime
                                      id="datetime"
                                      presentation="date"
                                      className="datebox"
                                      value={ConfirmOrder.delivery_date}
                                      onIonChange={(e) =>
                                        setConfirmOrder({
                                          ...ConfirmOrder,
                                          delivery_date: e.detail.value.slice(
                                            0,
                                            10
                                          ),
                                        })
                                      }
                                      displayFormat="YYYY-MM-DD"
                                      required
                                    ></IonDatetime>
                                  </IonModal>
                                </IonRow>
                              </IonCol>

                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      FOB Point
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonSelect
                                    placeholder="Select A FOB Point"
                                    className="twocolinputconfirm"
                                    value={ConfirmOrder.fob_point}
                                    onIonChange={(e) =>
                                      setConfirmOrder({
                                        ...ConfirmOrder,
                                        fob_point: e.detail.value,
                                      })
                                    }
                                  >
                                    <IonSelectOption value="Origin">
                                      Origin
                                    </IonSelectOption>
                                    <IonSelectOption value="Destination">
                                      Destination
                                    </IonSelectOption>
                                  </IonSelect>
                                </IonRow>
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Seller Notes
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonTextarea
                                  placeholder="Enter Your Notes Here"
                                  className="textareaconfirm"
                                  required
                                  rows={3}
                                  autoGrow
                                  value={ConfirmOrder.seller_notes}
                                  onIonChange={(e) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      seller_notes: e.target.value,
                                    })
                                  }
                                />
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Other Fee
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonInput
                                  type="number"
                                  placeholder="Enter Fee"
                                  className="twocolinputconfirm"
                                  value={ConfirmOrder.other_fee}
                                  onIonChange={(event) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      other_fee: event.target.value,
                                    })
                                  }
                                />
                              </IonCol>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Delivery Fee
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonInput
                                  type="number"
                                  placeholder="Enter Delivery Fee"
                                  className="twocolinputconfirm"
                                  value={ConfirmOrder.delivery_fee}
                                  onIonChange={(event) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      delivery_fee: event.target.value,
                                    })
                                  }
                                />
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Tax Fee
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonInput
                                  type="number"
                                  placeholder="Enter Tax Fee"
                                  className="twocolinputconfirm"
                                  value={ConfirmOrder.tax_fee}
                                  onIonChange={(event) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      tax_fee: event.target.value,
                                    })
                                  }
                                />
                              </IonCol>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Total
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonInput
                                  type="number"
                                  placeholder="Enter Total"
                                  className="twocolinputconfirm"
                                  value={ConfirmOrder.item_total}
                                  onIonChange={(event) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      item_total: event.target.value,
                                    })
                                  }
                                />
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Terms
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonSelect
                                  placeholder="Select Terms of Payment"
                                  className="twocolinputconfirm"
                                  value={ConfirmOrder.terms}
                                  onIonChange={(event) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      terms: event.target.value,
                                    })
                                  }
                                >
                                  <IonSelectOption value="half">
                                    50%
                                  </IonSelectOption>
                                  <IonSelectOption value="full">
                                    100%
                                  </IonSelectOption>
                                  <IonSelectOption value="15">
                                    15 Days
                                  </IonSelectOption>
                                  <IonSelectOption value="30">
                                    30 Days
                                  </IonSelectOption>
                                  <IonSelectOption value="45">
                                    45 Days
                                  </IonSelectOption>
                                  <IonSelectOption value="60">
                                    60 Days
                                  </IonSelectOption>
                                </IonSelect>
                              </IonCol>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Sales Agent
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonSelect
                                  placeholder="Select Assigned Sales Agent"
                                  className="twocolinputconfirm"
                                  value={ConfirmOrder.sales_agent}
                                  onIonChange={(event) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      sales_agent: event.target.value,
                                    })
                                  }
                                >
                                  {UserAgents?.filter((user) => {
                                    if (
                                      user.groups === 4 &&
                                      user.company == CompanyID
                                    ) {
                                      return user;
                                    }
                                  }).map((user, index) => (
                                    <IonSelectOption
                                      key={index.toString()}
                                      value={user.id}
                                    >
                                      {user.first_name} {user.last_name}
                                    </IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol>
                                <IonRow>
                                  <IonCol className="marginpadding">
                                    <IonLabel className="confirmmodallabel">
                                      Shipped Via
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonInput
                                  placeholder="Enter Shipper"
                                  className="textareaconfirm"
                                  value={ConfirmOrder.shipped_via}
                                  onIonChange={(event) =>
                                    setConfirmOrder({
                                      ...ConfirmOrder,
                                      shipped_via: event.target.value,
                                    })
                                  }
                                />
                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol>
                                {!spinner && (
                                  <IonButton
                                    style={{ float: "right" }}
                                    onClick={() =>
                                      Action(detailedTransaction.id, "Confirm")
                                    }
                                  >
                                    DONE
                                  </IonButton>
                                )}
                                {spinner && (
                                  <IonButton
                                    style={{ float: "right" }}
                                    onClick={() =>
                                      Action(detailedTransaction.id, "Confirm")
                                    }
                                  >
                                    <IonSpinner></IonSpinner>
                                  </IonButton>
                                )}
                              </IonCol>
                            </IonRow>
                          </IonContent>
                        </IonModal>
                      </IonCol>
                    </IonRow>
                  ) : null}

                  {detailedTransaction?.status === "Confirm" ? (
                    <IonRow>
                      <IonCol style={{ textAlign: "center" }}>
                        {!spinner && (
                          <IonButton
                            onClick={() =>
                              delivered(detailedTransaction.id, "Delivered")
                            }
                          >
                            Delivered
                          </IonButton>
                        )}
                        {spinner && (
                          <IonButton
                            onClick={() =>
                              delivered(detailedTransaction.id, "Delivered")
                            }
                          >
                            <IonSpinner></IonSpinner>
                          </IonButton>
                        )}
                      </IonCol>
                    </IonRow>
                  ) : null}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonApp>
  );
};

export default Orders;
