import { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import configs from "../configs/index";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { LocalNotifications } from "@capacitor/local-notifications";

const AuthContext = createContext("");
export default AuthContext;
export const AuthProvider = ({ children }) => {
  let [loading, setLoading] = useState(true);
  const [accesstoken, setAccessToken] = useState(localStorage.getItem("access token"));
  const [refreshtoken, setRefreshToken] = useState(localStorage.getItem("refresh token"));
  

  let loginUser = async (e) => {
    e.preventDefault();
    const cookiesToRemove = [
      "my-cookie",
      "sessionid",
      "csrftoken",
      "my-refresh-token",
    ];
    cookiesToRemove.forEach((cookieName) => {
      if (document.cookie.indexOf(`${cookieName}=`) !== -1) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    });
    return axios
      .post("https://admin.op.legiontech.dev/api/v1/login/", {
        email: e.target.email.value,
        password: e.target.password.value,
      })
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("access token", response.data.access_token);
        localStorage.setItem("refresh token", response.data.refresh_token);
        window.location.assign("/Orders");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let removeUser = () => {
    localStorage.removeItem("acccess");
    localStorage.removeItem("refresh");
  };

  const logoutUser = async (e) => {
    e.preventDefault();
    return axios
      .post(`${configs.base_url}${configs.api_url}logout/`)
      .then((response) => {
        localStorage.removeItem("access token");
        localStorage.removeItem("refresh token");
        window.location.assign("/");
        return response.data;
      });
  };

  let contextData = {
    accesstoken: accesstoken,
    loginUser: loginUser,
    logoutUser: logoutUser,
  };


  useEffect(() => {
    const updateToken = async () => {
      let response = await fetch(
        `${configs.base_url}${configs.api_url}token/refresh/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          body: JSON.stringify({ refresh: refreshtoken }),
        }
      );

      let data = await response.json();
      // console.log(data);

      if (response.status === 200) {
        localStorage.setItem("access token", data.access);
        setAccessToken(data.access);
      } else {
        removeUser();
      }

      if (loading) {
        setLoading(false);
      }
    };

    if (loading) {
      updateToken();
    }

    let fourMinutes = 1000 * 60 * 4;

    let interval = setInterval(() => {
      if (refreshtoken) {
        updateToken();
        console.log("refreshed");
      }
    }, fourMinutes);

    return () => clearInterval(interval);
  }, [refreshtoken, loading]);


  //Realtime Notification START
  const [UUID, setUUID] = useState();
  // const [DataNotif, setDataNotif] = useState();
  const [socketUrl, setSocketUrl] = useState(
    `ws://${configs.base_url}${configs.api_url}events/notification/?uuid=${UUID}`
  );

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          `wss://${configs.ws_url}${configs.api_url}events/notification/?uuid=${UUID}`
        );
      }, 1000);
    });
  }, [UUID]);
  useEffect(() => {
    const GetUUID = async (e) => {
      return axios
        .get(`${configs.base_url}${configs.api_url}ws/auth/`, {
          headers: {
            authorization: "Bearer " + accesstoken,
          },
        })
        .then((response) => {
          // console.log(UUID);
          setUUID(response.data.uuid);

          // handle success
        })
        .catch((error) => {
          console.log(error);
        });
    };
    GetUUID();
  }, []);

  const { lastMessage, readyState } = useWebSocket(getSocketUrl, {});

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  // console.log(connectionStatus);


  const DataNotif = lastMessage ? JSON.parse(lastMessage.data) : "Waiting";
  // console.log(DataNotif);

  useEffect(() => {
    if (lastMessage) {
      Notification();
    }
  }, [lastMessage]);

  async function Notification() {
    let options = {
      notifications: [
        {
          id: DataNotif.message?.order_id,
          title: "Order Notification",
          body:
            DataNotif.message?.message +
            "\nOrder Number: " +
            DataNotif.message?.order_no,
          summaryText: " Order Number: " + DataNotif.message?.order_no,
          foreground: true,
        },
      ],
    };
    if ((await LocalNotifications.requestPermissions().display) === "granted") {
      await LocalNotifications.schedule(options);
    }
    try {
      await LocalNotifications.schedule(options);
    } catch (ex) {
      alert(JSON.stringify(ex));
    }
  }

  //Realtime Notification END

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

