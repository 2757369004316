import {
  IonContent,
  IonPage,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonInput,
  IonPopover,
  IonSpinner,
} from "@ionic/react";
import "./Login.css";
import { useState, useContext } from "react";
import i18n from "../../Translations/i18nConfigs";
import axios from "axios";

import Logo from "../../assets/gm_logo.png";
import configs from "../../configs/index";
import AuthContext from "../../context/AuthContext";


const Login = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [Validations, setValidations] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const API = () => {
    window.location.assign("/registration");
  };
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  console.log(data);

  const LoginAPI = (e) => {
    setSpinner(true);
    e.preventDefault();
    axios
      .post(`${configs.base_url}${configs.api_url}login/`, data)
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("access token", response.data.access_token);
        localStorage.setItem("refresh token", response.data.refresh_token);
        localStorage.setItem("pk", response.data.user.pk);
        window.location.assign("/Orders");
        console.log(response.data);
        setSpinner(false);
        setShowPopover(true);
      })
      .catch((error) => {
        console.log(error);
        setValidations(error.response.data);
        setSpinner(false);
        setShowPopover2(true);
      });
  };
  //functions for login
  return (
    <>
      <IonPage>
        <IonContent>
          <IonGrid className="container-grid">
            <IonRow>
              <IonCol className="container-col" size="12" size-sm="3" size-md="12" size-lg="6">
                <IonRow className="rightcol">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonLabel>SUPPLIER PLATFORM</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonLabel>WILLKOMMEN BEI DER GASTRONOMIA GMBH</IonLabel>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol size="12" size-sm="3" size-md="12" size-lg="6">
                <IonRow className="leftcol">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <img
                          src={Logo}
                          alt="Gastronomia Logo"
                          className="logoimg"
                        ></img>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonLabel className="pageheader">{i18n.t('login.title')}</IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <form onSubmit={LoginAPI}>
                          <IonRow>
                            <IonCol>
                              <IonLabel>{i18n.t('login.email')}</IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonInput
                                placeholder={i18n.t('login.emailPlaceholder')}
                                id="emailtxt"
                                name="email"
                                value={data.email}
                                onIonChange={(event) =>
                                  setData({
                                    ...data,
                                    email: event.target.value,
                                  })
                                }
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonLabel>{i18n.t('login.password')}</IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonInput
                                placeholder={i18n.t('login.passwordPlaceholder')}
                                id="passwordtxt"
                                type="password"
                                name="password"
                                value={data.password}
                                onIonChange={(event) =>
                                  setData({
                                    ...data,
                                    password: event.target.value,
                                  })
                                }
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              {!spinner && (
                                <IonButton
                                  expand="block"
                                  className="loginbtn"
                                  type="submit"
                                >
                                  {i18n.t('login.title')}
                                </IonButton>
                              )}
                              {spinner && (
                                <IonButton
                                  expand="block"
                                  className="loginbtn"
                                  type="submit"
                                >
                                  <IonSpinner></IonSpinner>
                                </IonButton>
                              )}
                              <IonPopover
                                isOpen={showPopover}
                                onDidDismiss={() => setShowPopover(false)}
                                className="confirmationbox"
                              >
                                <p className="confirmationmessage">
                                  Login successful!
                                </p>
                              </IonPopover>

                              <IonPopover
                                className="confirmationbox"
                                isOpen={showPopover2}
                                onDidDismiss={() => setShowPopover2(false)}
                              >
                                {Validations &&
                                  Object.keys(Validations).map(
                                    (fieldName, index) => (
                                      <p
                                        className="confirmationmessage"
                                        key={index.toString()}
                                      >
                                        {`${fieldName}: ${Validations[
                                          fieldName
                                        ].join(", ")}`}
                                      </p>
                                    )
                                  )}
                              </IonPopover>
                            </IonCol>
                          </IonRow>
                        </form>
                      </IonCol>
                    </IonRow>
                    {/* <IonRow className="signuprow">
                      <IonCol>
                        <IonLabel> {i18n.t('login.noAccount')}</IonLabel> {""}
                        <IonLabel className="link" onClick={API}>
                        {i18n.t('login.signup')}
                        </IonLabel>
                      </IonCol>
                    </IonRow> */}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Login;
