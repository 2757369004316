import { useState, useEffect, useContext } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  IonLabel,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonPopover,
  IonCard,
  IonCardContent,
  IonContent,
  IonSpinner,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonApp,
} from "@ionic/react";
import Navigation from "../../components/Navigation";
import "./Account.css";
import {
  cameraOutline,
  ellipsisHorizontal,
  ellipsisVertical,
} from "ionicons/icons";
import axios from "axios";
import configs from "../../configs/index";
import i18n from "../../Translations/i18nConfigs";
import AuthContext from "../../context/AuthContext";

const Account = () => {
  const { accesstoken } = useContext(AuthContext);
  const CompanyID = localStorage.getItem("companyID");
  const pk = localStorage.getItem("pk");

  const [CompanyData, setCompanyData] = useState({
    name: "",
    delivery_cost: "",
    min_order: "",
    cut_off: "",
    delivery: "",
  });

  // console.log(CompanyData);

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [showError, setshowError] = useState({
    error: "",
  });
  const [UserCompany, setUserCompany] = useState([]);
  const [spinner, setSpinner] = useState(false);

  console.log(UserCompany);

  const CreateCompany = async (e) => {
    setSpinner(true);
    return axios
      .post(
        `${configs.base_url}${configs.api_url}users/company/`,
        CompanyData,
        {
          headers: {
            authorization: "Bearer " + accesstoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setShowPopover(true);
        setSpinner(false);

        setTimeout(() => {
          window.location.assign("/orders");
        }, 2000);
      })
      .catch((error) => {
        const errormessage = error.response.data;
        setshowError({
          ...showError,
          error: errormessage.message,
        });
        console.log(error);
        setShowPopover2(true);
        setSpinner(false);
      });
  };

  const EditCompany = (e) => {
    // e.preventDefault();
    setSpinner(true);
    axios
      .put(
        `${configs.base_url}${configs.api_url}users/company/` +
          UserCompany.id +
          `/`,
        {
          name: CompanyData.name ? CompanyData.name : UserCompany.name,
          delivery_cost: CompanyData.delivery_cost
            ? CompanyData.delivery_cost
            : UserCompany.delivery_cost,
          min_order: CompanyData.min_order
            ? CompanyData.min_order
            : UserCompany.min_order,
          cut_off: CompanyData.cut_off
            ? CompanyData.cut_off
            : UserCompany.cut_off,
          delivery: CompanyData.delivery
            ? CompanyData.delivery
            : UserCompany.delivery,
        },
        {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        }
      )
      .then((response) => {
        setUserCompany(response);
        setSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  useEffect(() => {
    const User = (e) => {
      setSpinner(true);
      axios
        .get(`${configs.base_url}${configs.api_url}userdetail/`, {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        })
        .then((response) => {
          setUserCompany(response.data.company[0]);
          setSpinner(false);
        })
        .catch((error) => {
          console.log(error);
          setSpinner(false);
        });
    };
    User();
  }, []);

  const [UserAgents, setUserAgents] = useState([]);

  useEffect(() => {
    const agents = (e) => {
      setSpinner(true);
      axios
        .get(`${configs.base_url}${configs.api_url}users/list/`, {
          headers: {
            Authorization: "Bearer " + accesstoken,
          },
        })
        .then((response) => {
          // console.log(response);
          setUserAgents(response.data);
          setSpinner(false);
        })
        .catch((error) => {
          console.log(error);
          setSpinner(false);
        });
    };
    agents();
  }, []);

  // screensize
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsSmallScreen(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const [showPopover3, setShowPopover3] = useState(false);
  const [loadingforcreate, setloadingforcreate] = useState(false);
  const [Validations, setValidations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [AgentCreate, setAgentCreate] = useState({
    email: "",
    username: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    mob_number: "",
    groups: "agent",
  });
  console.log(AgentCreate);
  const CreateAgent = async (e) => {
    setSpinner(true);
    return axios
      .post(
        `${configs.base_url}${configs.api_url}users/register-agent/`,
        AgentCreate,
        {
          headers: {
            authorization: "Bearer " + accesstoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setShowPopover(true);

        setTimeout(() => {
          window.location.assign("/account");
          setSpinner(false);
        }, 2000);
      })
      .catch((error) => {
        const errormessage = error.response.data;
        setValidations(errormessage);
        console.log(error);
        setShowPopover2(true);
        setSpinner(false);
      });
  };

  return (
    <IonApp>
      <IonContent>
        <Navigation />
        <form onSubmit={UserCompany.id ? EditCompany : CreateCompany}>
          <IonGrid>
            <IonRow>
              <IonCol className="pagetitle">
                <h3>{i18n.t("company.title")}</h3>
              </IonCol>
            </IonRow>

            <div className="infoinputs">
              <IonRow>
                <IonCol size-xs="12" size-sm="6" size-md="6" size-lg="4">
                  <IonRow>
                    <IonCol>
                      <IonLabel className="passlabel">
                        {i18n.t("company.companyName")}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        placeholder="Enter Company Name"
                        className="passinput"
                        value={
                          CompanyData && CompanyData.name
                            ? CompanyData.name
                            : UserCompany.name
                        }
                        onIonChange={(event) =>
                          setCompanyData({
                            ...CompanyData,
                            name: event.target.value,
                          })
                        }
                      />
                    </IonCol>
                  </IonRow>
                </IonCol>

                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonLabel className="passlabel">
                        {i18n.t("company.deliveryCost")}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        placeholder="Enter Delivery Cost"
                        className="passinput"
                        type="number"
                        value={
                          CompanyData && CompanyData.delivery_cost
                            ? CompanyData.delivery_cost
                            : UserCompany.delivery_cost
                        }
                        onIonChange={(event) =>
                          setCompanyData({
                            ...CompanyData,
                            delivery_cost: event.target.value,
                          })
                        }
                      />
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size-xs="12" size-sm="6" size-md="6" size-lg="4">
                  <IonRow>
                    <IonCol>
                      <IonLabel className="passlabel">
                        {i18n.t("company.orderAmount")}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        placeholder="Enter Minimum Order Amount"
                        className="passinput"
                        type="number"
                        value={
                          CompanyData && CompanyData.min_order
                            ? CompanyData.min_order
                            : UserCompany.min_order
                        }
                        onIonChange={(event) =>
                          setCompanyData({
                            ...CompanyData,
                            min_order: event.target.value,
                          })
                        }
                      />
                    </IonCol>
                  </IonRow>
                </IonCol>

                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonLabel className="passlabel">
                        {i18n.t("company.cutOff")}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonSelect
                        placeholder="Select A Day"
                        className="passinput"
                        value={
                          CompanyData && CompanyData.cut_off
                            ? CompanyData.cut_off
                            : UserCompany.cut_off
                        }
                        onIonChange={(event) =>
                          setCompanyData({
                            ...CompanyData,
                            cut_off: event.target.value,
                          })
                        }
                      >
                        <IonSelectOption value="mon">
                          {i18n.t("company.monday")}
                        </IonSelectOption>
                        <IonSelectOption value="tue">
                          {i18n.t("company.tuesday")}
                        </IonSelectOption>
                        <IonSelectOption value="wed">
                          {i18n.t("company.wednesday")}
                        </IonSelectOption>
                        <IonSelectOption value="th">
                          {i18n.t("company.thursday")}
                        </IonSelectOption>
                        <IonSelectOption value="fri">
                          {i18n.t("company.friday")}
                        </IonSelectOption>
                        <IonSelectOption value="sat">
                          {i18n.t("company.saturday")}
                        </IonSelectOption>
                        <IonSelectOption value="sun">
                          {i18n.t("company.sunday")}
                        </IonSelectOption>
                      </IonSelect>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size-xs="12" size-sm="6" size-md="6" size-lg="4">
                  <IonRow>
                    <IonCol>
                      <IonLabel className="passlabel">
                        {i18n.t("company.delivery")}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonSelect
                        placeholder="Select A Day"
                        className="passinput"
                        value={
                          CompanyData && CompanyData.delivery
                            ? CompanyData.delivery
                            : UserCompany.delivery
                        }
                        onIonChange={(event) =>
                          setCompanyData({
                            ...CompanyData,
                            delivery: event.target.value,
                          })
                        }
                      >
                        <IonSelectOption value="mon">
                          {i18n.t("company.monday")}
                        </IonSelectOption>
                        <IonSelectOption value="tue">
                          {i18n.t("company.tuesday")}
                        </IonSelectOption>
                        <IonSelectOption value="wed">
                          {i18n.t("company.wednesday")}
                        </IonSelectOption>
                        <IonSelectOption value="th">
                          {i18n.t("company.thursday")}
                        </IonSelectOption>
                        <IonSelectOption value="fri">
                          {i18n.t("company.friday")}
                        </IonSelectOption>
                        <IonSelectOption value="sat">
                          {i18n.t("company.saturday")}
                        </IonSelectOption>
                        <IonSelectOption value="sun">
                          {i18n.t("company.sunday")}
                        </IonSelectOption>
                      </IonSelect>
                    </IonCol>
                  </IonRow>
                </IonCol>

                <IonCol></IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="savecompanycol">
                  {spinner ? (
                    <IonButton className="savecompanybtn" type="submit">
                      <IonSpinner></IonSpinner>
                    </IonButton>
                  ) : (
                    <IonButton className="savecompanybtn" type="submit">
                      {i18n.t("company.save")}
                    </IonButton>
                  )}

                  <IonPopover
                    className="confirmationpop"
                    isOpen={showPopover}
                    onDidDismiss={() => setShowPopover(false)}
                  >
                    <p className="confirmation">
                      Company Creation successful!<br></br> Wait for a moment
                      Redirecting to Orders.....
                    </p>
                  </IonPopover>

                  <IonPopover
                    className="confirmationpop"
                    isOpen={showPopover2}
                    onDidDismiss={() => setShowPopover2(false)}
                  >
                    <p className="confirmation">{showError.error}</p>
                  </IonPopover>
                </IonCol>
              </IonRow>
            </div>

            <IonRow style={{ marginTop: "3rem" }}>
              <IonCol className="salesmembercol">
                <h3 className="salesmembertitle">{i18n.t("salesmember.title")}</h3>
              </IonCol>

              <IonCol className="addsalesmembercol">
                <IonButton
                  className="addsalesmemberbtn"
                  onClick={() => setIsOpen(true)}
                >
                  {" "}
                  {i18n.t("salesmember.addButtonMember")}
                </IonButton>
              </IonCol>
            </IonRow>
            {!spinner ? (
              <IonRow style={{ marginTop: "3rem" }}>
                <IonCol>
                  {UserAgents?.filter((user) => {
                    if (user.groups === 4 && user.company == CompanyID) {
                      return user;
                    }
                  }).map((user, index) => (
                    <IonCard key={index.toString()} className="cardsalemember">
                      <IonCardContent>
                        <IonGrid>
                          <IonRow style={{ textAlign: "center" }}>
                            <IonCol
                              size-xs="12"
                              size-sm="6"
                              size-md="4"
                              size-lg="2"
                              size-lx="2"
                            >
                              <h4>
                                <strong>
                                  {user.first_name} {user.last_name}
                                </strong>
                              </h4>
                            </IonCol>
                            <IonCol
                              size-xs="12"
                              size-sm="6"
                              size-md="4"
                              size-lg="2"
                              size-lx="2"
                            >
                              <h4>
                                <strong>{user.email}</strong>
                              </h4>
                            </IonCol>
                            <IonCol className="marginpadding">
                              <IonButton className="categorybutton">
                                {user.groups === 4
                                  ? `${i18n.t("salesmember.salesAgent")}`
                                  : user.groups === 2
                                  ? "Customer"
                                  : user.groups === 3
                                  ? "Supplier"
                                  : "ADMIN"}
                              </IonButton>
                            </IonCol>

                            <IonCol
                              size-xs="12"
                              size-sm="6"
                              size-md="4"
                              size-lg="2"
                              size-lx="2"
                            >
                              <IonIcon
                                className="threedots"
                                id={`threedots-${index}`}
                                icon={
                                  isSmallScreen
                                    ? ellipsisHorizontal
                                    : ellipsisVertical
                                }
                              />
                              <IonPopover
                                dismiss-on-select="true"
                                alignment="center"
                                id={`popover-${index}`}
                                trigger={`threedots-${index}`}
                                triggerAction="click"
                                // side={isSmallScreen ? "top" : "left"}
                              >
                                <IonContent class="ion-padding">
                                  <IonRow>
                                    <IonCol>
                                      <p
                                      // onClick={() => {
                                      //   ViewShow(
                                      //     item.name,
                                      //     item.product_id,
                                      //     item.price,
                                      //     item.units,
                                      //     item.category,
                                      //     item.description,
                                      //     item.image,
                                      //     item.owner.id,
                                      //     item.sale
                                      //   );
                                      //   seteditID(item.id);
                                      // }}
                                      >
                                        {i18n.t("salesmember.edit")}
                                      </p>
                                    </IonCol>
                                  </IonRow>
                                  <IonRow>
                                    <IonCol>
                                      <p
                                      // onClick={() => {
                                      //   DeleteProduct(item.id);
                                      // }}
                                      >
                                        {i18n.t("salesmember.delete")}
                                      </p>
                                    </IonCol>
                                  </IonRow>
                                </IonContent>
                              </IonPopover>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  ))}
                </IonCol>
              </IonRow>
            ) : (
              <IonRow className="salesmemberrow">
                <IonCol className="cardproductinfocol">
                  <IonCard className="cardproductinfo">
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <IonSpinner></IonSpinner>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </form>

        <IonModal isOpen={isOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{i18n.t("salesmember.titleModal")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonRow>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel className="agentusernamelabel">{i18n.t("salesmember.email")}</IonLabel>
                    <IonInput
                      placeholder={i18n.t("salesmember.emailPlaceholder")}
                      className="agentusernameinput"
                      value={AgentCreate.email}
                      onIonChange={(event) =>
                        setAgentCreate({
                          ...AgentCreate,
                          email: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>

                  <IonCol>
                    <IonLabel className="agentusernamelabel">{i18n.t("salesmember.username")}</IonLabel>
                    <IonInput
                      placeholder={i18n.t("salesmember.usernamePlaceholder")}
                      className="agentusernameinput"
                      value={AgentCreate.username}
                      onIonChange={(event) =>
                        setAgentCreate({
                          ...AgentCreate,
                          username: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonLabel className="agentusernamelabel">{i18n.t("salesmember.password")}</IonLabel>
                    <IonInput
                      placeholder={i18n.t("salesmember.passwordPlaceholder")}
                      className="agentusernameinput"
                      value={AgentCreate.password1}
                      onIonChange={(event) =>
                        setAgentCreate({
                          ...AgentCreate,
                          password1: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>

                  <IonCol>
                    <IonLabel className="agentusernamelabel">
                    {i18n.t("salesmember.confirmPass")}
                    </IonLabel>
                    <IonInput
                      placeholder="Re-Enter Your Password"
                      className="agentusernameinput"
                      value={AgentCreate.password2}
                      onIonChange={(event) =>
                        setAgentCreate({
                          ...AgentCreate,
                          password2: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonLabel className="agentusernamelabel">
                    {i18n.t("salesmember.firstName")}
                    </IonLabel>
                    <IonInput
                      placeholder={i18n.t("salesmember.firstNamePlaceholder")}
                      className="agentusernameinput"
                      value={AgentCreate.first_name}
                      onIonChange={(event) =>
                        setAgentCreate({
                          ...AgentCreate,
                          first_name: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>

                  <IonCol>
                    <IonLabel className="agentusernamelabel">
                    {i18n.t("salesmember.lastName")}
                    </IonLabel>
                    <IonInput
                      placeholder={i18n.t("salesmember.lastNamePlaceholder")}
                      className="agentusernameinput"
                      value={AgentCreate.last_name}
                      onIonChange={(event) =>
                        setAgentCreate({
                          ...AgentCreate,
                          last_name: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonLabel className="agentusernamelabel">
                    {i18n.t("salesmember.mobileNumber")}
                    </IonLabel>
                    <IonInput
                      placeholder={i18n.t("salesmember.mobileNumberPlaceholder")}
                      className="agentusernameinput"
                      value={AgentCreate.mob_number}
                      onIonChange={(event) =>
                        setAgentCreate({
                          ...AgentCreate,
                          mob_number: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <IonRow>
              <div className="modalactionbtnagent">
                <IonCol size="auto">
                  <IonButton
                    className="cancelcreatagent"
                    onClick={() => setIsOpen(false)}
                  >
                    {i18n.t("salesmember.cancel")}
                  </IonButton>
                </IonCol>

                <IonCol size="auto">
                  {spinner ? (
                    <IonButton className="creatagent" onClick={CreateAgent}>
                      <IonSpinner />
                    </IonButton>
                  ) : (
                    <IonButton className="creatagent" onClick={CreateAgent}>
                      {i18n.t("salesmember.save")}
                    </IonButton>
                  )}
                  <IonPopover
                    className="confirmationpop"
                    isOpen={showPopover3}
                    onDidDismiss={() => setShowPopover3(false)}
                  >
                    <p className="confirmation">
                      Product Creation successful!<br></br> Wait for a moment
                      Redirecting to Products.....
                    </p>
                  </IonPopover>

                  <IonPopover
                    className="confirmationpop"
                    isOpen={showPopover2}
                    onDidDismiss={() => setShowPopover2(false)}
                  >
                    <p className="confirmation">
                      {JSON.stringify(Validations)}
                    </p>
                  </IonPopover>
                </IonCol>
              </div>
            </IonRow>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonApp>
  );
};
export default Account;
